import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [ 

{
  id: 'process',
  title: 'Process',
  translate: 'Process',
  type: 'item',
  icon: 'settings',
  url: '/process-pages/process-lp'
},
  {
    id: 'forms',
    title: 'Forms',
    translate: 'Forms',
    type: 'item',
    icon: 'file-text',
    url: '/input-config-pages/forms-lp'
  },
  {
    id: 'publish-process',
    title: 'Publish Process',
    translate: 'Publish Process',
    type: 'item',
    icon: 'globe',
    url: '/publish-process/publish-process-lp'
  },
  {
    id: 'template',
    title: 'Templates',
    translate: 'Templates',
    type: 'item',
    icon: 'layers',
    url: '/template/templates-lp'
  },
  {
    id: 'microflows',
    title: 'Microflows',
    translate: 'Microflows',
    type: 'item',
    icon: 'git-merge',
    url: '/microflows/microflow-lp'
  }
]
