import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
 
import { AuthenticationService } from 'app/auth/service';
import { CookieService } from 'ngx-cookie-service';


@Injectable() 
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _authenticationService: AuthenticationService, private cookieService: CookieService) {}

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
    
 
    if(localStorage.getItem("token")){      
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          clientid: `${localStorage.getItem("clientid")}`,
        }
      });
    }

    return next.handle(request);
  }
}
