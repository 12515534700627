import { KeyNameI } from "app/interface/common-interface";
import { TemplatesSettingsInterface } from "app/main/pages/templates/interface/templates-interface";
import { MICROFLOWS_INPUT_FIELD_DATA } from "./inputFieldConfig";

export const TEMPLATE_REF_INBOX: KeyNameI[] = [
    { key: "vendor", name: 'Vendor Inbox' },
    { key: "invoice", name: 'Invoice Inbox' },
    { key: "pr to po",name: 'Invoice Inbox' },
    { key: "mdm",name: 'MDM Inbox' }
  ];

  export const PUBLISH_PROCESS_INBOX: KeyNameI[] = [
    { key: "vendorInbox", name: 'Vendor Inbox' },
    { key: "invoiceInbox", name: 'Invoice Inbox' },
    { key: "prToPoInbox", name: 'PR To PO Inbox' },
    { key: "mdmInbox", name: 'MDM Inbox' }
  ];

  export const TEMPLATE_DEFAULT_VALUE: TemplatesSettingsInterface = {
    format:"A4", 
    headerHeight:"38mm", 
    footerHeight:"10mm", 
    zoom:90, 
    orientation:"portrait",
  }

  export const RENDER_TYPE_OPTONS :KeyNameI[] = [
    { key: 'auto-complete', name: 'Auto Complete' },
    { name: 'Date', key: 'date' },
    { key: "uploadBtn", name: 'Upload Btn' },
    { name: 'Date View', key: 'dateView' },
    { key: 'toDecimal', name: 'toDecimal' },
    { key: 'number', name: 'Number' },
    { key: 'number-10-without-decimal', name: 'Number 10 without decimal' },
    { key: 'view', name: 'View' },
    { key: 'text', name: 'Text' },
    { key: 'select', name: 'Drop Down' },
    { key: 'hide', name: 'Hide' },
    { name: 'Currency View', key: 'currency-view' },
    { name: 'Image', key: 'img' }];

    export const INPUT_TYPE_LIST :KeyNameI[] = [
        { key: "input", name: 'Input' },
        { key: 'select', name: 'Drop Down' },
        { key: "number-currency", name: 'Number' },
        { key: "datepicker", name: 'Date' },
        { key: "uploadBtn", name: 'Upload Btn' },
        { key: "auto-complete", name: 'Auto Complete' },
        { key: 'view', name: 'View' }];

    

  export const RULE_CONDITIONS: KeyNameI[] = [
  { name: 'is equal to ', key: 'eq' },
  { name: 'is less than', key: 'lt' },
  { name: 'is greater than', key: 'gt' },
  { name: 'is less than equal to', key: 'lte' },
  { name: 'is greater than equal to', key: 'gte' },
  { name: 'is not equal to', key: 'neq' },
  { name: 'is not', key: 'not' },
  { name: 'Like', key: 'like' },
  { name: 'RO', key: 'RO' },
  { name: 'Assign User Id', key: 'assignUserId' },
  { name: 'is not Assign User Id', key: 'isnotassignUserId' },
  { name: 'Display Roles', key: 'displayRoles' },
  { name: 'Forward Status', key: 'forwardStatus' },
  { name: 'Date Diff', key: 'date-diff' },
  { name: 'User Roles', key: 'userRoles' }
]



export const DATA = {
    AUTH_PAGE:{
        DEFAULT_LOGGEDIN_USER_NAVIGATION :'/process-pages/process-lp', 
        DEFAULT_LOGGEDIN_ADMIN_USER_NAVIGATION :'/clients/clients-lp', 
        DEFAULT_LOGGIN_PAGE :'/auth/login', 
    },
    API_STATUS: {
        SUCCESS: 'success',
        ERROR: 'error',
        SUCCESS_STATUS_CODE: 200,
        INVALID_TOKEN_STRING: 'Invalid token',
        ERROR_MSG: 'Something went wrong!!!'
    },
    TOASTER:{
        SUCCESS_TITLE: 'Success',
        ERROR_TITLE: 'Error',
    },
    PAGINATION: {
        CURRENT_PAGE: 1,
        PAGE_SIZE: 20,
        PAGE_MIN_SIZE: 9,
        MAX_PAGE_SIZE:99,
        MAX_SIZE:999,
    },
    PUBLISH_PROCESS_APP_MENU: {
        PROCESS_APP_MENU_PATH: '/v2/modules/invoice/?processName=',
        PROCESS_APP_MENU_ICON: 'TableOutlined',
        BREADCRUMB_DEFAULT_SETTING: false,
        KEY_DEFAULT: 'create-invoice-',
        TITLE_DEFAULT: 'sidenav.createInvoice.',
    },
    INPUT_TYPE_LIST : [{ key: "input", name: 'Input' }, { key: "number-currency", name: 'Number' }, { key: "datepicker", name: 'Date' }, { key: "uploadBtn", name: 'Upload Btn' }, { key: "auto-complete", name: 'Auto Complete' },{ key: 'view', name: 'View' }],
    RULE_TYPE: [{ key: 'OR', name: 'OR' }, { key: 'AND', name: 'AND' }],
    RULE_ACTION: [{ key: 'hide', name: 'Hide' }, { key: 'readOnly', name: 'Read Only' },{ key: 'hideandClear', name: 'Hide & Clear' }],
    FIELD_TYPES: [{ name: 'View', key: 'view' },
    { key: 'number', name: 'Number' },
    { name: 'Radio', key: 'radio' },
    { name: 'Date', key: 'date' },
    { name: 'Date View', key: 'dateView' },
    { name: 'Currency View', key: 'currency-view' }],
    TRUE_FALSE: [{ key: true, name: 'Yes' }, { key: false, name: 'No' }],
    LEFT_RIGHT: [{ key: true, name: 'Left' }, { key: false, name: 'Right' }],
    EQUAL_CONDITION: [
        { name: 'is equal to ', key: 'eq' },
        { name: 'is less than', key: 'lt' },
        { name: 'is greater than', key: 'gt' },
        { name: 'is less than equal to', key: 'lte' },
        { name: 'is greater than equal to', key: 'gte' },
        { name: 'is not equal to', key: 'neq' },
        { name: 'Ilike', key: 'ilike' }
    ],
    EQUAL_NOT_EQUAL_CONDITION: [
        { name: 'is equal to ', key: 'eq' }, 
        { name: 'is not equal to', key: 'neq' }, 
    ],
    TEXT_TRANSFORM : [{name:'Uppercase', key:'uppercase'},{name:'Lowercase', key:'lowercase'},{name:'Camelcase', key:'camelcase'},{name:'Pascalcase', key:'pascalcase'},{name:'No Transform', key:'noTransform'}],
    EQUAL_CONDITION_FILTER : [ { name: 'is equal to ', key: '$eq' },{ name: 'not like ', key: '$notlike' },{ name: 'not null ', key: '$not:null' },{ name: 'between ', key: '$btw' }, { name: 'is less than', key: '$lt' }, { name: 'is greater than', key: '$gt' }, { name: 'is less than equal to', key: '$lte' }, { name: 'is greater than equal to', key: '$gte' }, { name: 'is not equal to', key: '$neq' }, { name: 'Ilike', key: '$ilike' }, { name: 'is not', key: '$not' }, { name: 'In', key: '$in' }, ],
    MODAL_FOOTER_BUTTON_TYPE : [{ key: 'cancel', name: 'Cancel' },{ key: 'fetch', name: 'Fetch' },],
    RENDER_TYPE_OPTONS: [{ key: 'auto-complete', name: 'Auto Complete' }, { name: 'Date', key: 'date' },
    { key: "uploadBtn", name: 'Upload Btn' },{ name: 'Date View', key: 'dateView' }, { key: 'toDecimal', name: 'toDecimal' }, { key: 'number', name: 'Number' }, { key: 'number-10-without-decimal', name: 'Number 10 without decimal' }, { key: 'view', name: 'View' }, { key: 'text', name: 'Text' }, { key: 'hide', name: 'Hide' }, { name: 'Currency View', key: 'currency-view' }, { name: 'Image', key: 'img' }],
    UPDATE_METHOD: [{ key: "post", name: 'POST' }, { key: "put", name: 'PUT' }],
    DELETE_METHOD: [{ key: "post", name: 'POST' }, { key: "delete", name: 'DELETE' }],
    BUTTON_TYPE: [{ key: "submit", name: 'Submit' }, { key: "button", name: 'Button' }],
    BODY_TYPE: [{ key: "array", name: 'Array' }, { key: "json", name: 'Json' }],
    METHOD_TYPE: [{ key: "get", name: 'GET' }, { key: "post", name: 'POST' }, { key: "put", name: 'PUT' }],
    GET_METHOD_TYPE: [{ key: "get", name: 'GET' }],
    FONT_WEIGHT_OPTIONS: [{ key: 300, name: '300' }, { key: 400, name: '400' }, { key: 500, name: '500' }, { key: 600, name: '600' }, { key: 700, name: '700' }],
    BUTTON_OPTIONS: [{ key: 'approve', name: 'Approve' },{ key: 'assign', name: 'Assign' },{ key: 'submit', name: 'Submit' }, { key: 'reject', name: 'Reject' },  { key: 'delegate', name: 'Delegate' }, { key: 'reAssign', name: 'Re-Assign' }, { key: 'unAssign', name: 'Un-Assign' }, { key: 'forward', name: 'Forward' }, { key: 'caseClose', name: 'Case Close' },{ key: 'AwaitingAdditionalInformation', name: 'Request for more info' },{ key: 'GenerateCertificate', name: 'Generate Certificate' },{ key: 'sendToModify', name: 'Send To Modify' }],
    RULE_REGEX: [{ key: '^([A-Za-z0-9 ])+$', name: 'No Special Characters' }, { key: '^[0-9]*$', name: 'Only Numbers' }, { key: '/^.{6,7}$/', name: 'Max Length 6' }, { key: '/^.{9,10}$/', name: 'Max Length 9' }, { key: 'other', name: 'Other' }],
    REQ_BODY_TYPE: [{ key: "array", name: 'Array' }, { key: "json", name: 'Json' }, { key: "object", name: 'Object' }],
    FILE_TYPE_LIST: [{ name: 'jpg', key:'image/jpeg' }, { name: 'png', key:'image/png' }, { name: 'pdf', key:'application/pdf' }, { name: 'email', key:'message/rfc822' }, { name: 'excel', key:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },{ name: 'xslx', key:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },{ name: 'docx', key:'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }, { name: 'excel', key:'application/vnd.ms-excel' }, { name: 'word', key:'application/msword' }, { name: 'zip', key:'application/zip' }, { name: 'text', key:'text/plain' }],
    BUTTON_TEXT:{
        FORM_INITIAL_NAME:'Form Name',
        PROCESS_INITIAL_NAME:'Process Name',
        SAVE_FORM : 'Save Form',
        UPDATE_FORM:'Update Form',
        SAVE_TEMPLATE:'Save Template',
        TEMPLATE_INITIAL_NAME:'Template Name',
        
       
    },
    TEXT_LENGTH:{
        NAME_LENGTH:20,
    },
    TOOLBOX_SIDEBAR : {
        SIDEBAR_TITLE : 'ToolBox'
    },
    MESSAGES :{
        UPLOAD_JSON_ONLY_ERROR:'Please select a JSON file.',
        FORM_SAVE_SUCESS:'Form Saved Successfully!',
        FORM_UPDATE:'Form Updated Successfully!',
        DELETE_FORM:'Form Deleted Successfully!',
        TEXT_FOR_BACKBUTTON_WARNING:'You have unsaved changes. If you leave this page, your changes will be lost.',
        TEMPLATE_VERSION_INPUT : 'Do you want to save as a new version?'
    },
    TOOLTIP :{
        FORM :  {
            DELETE: 'Delete this form',
            EDIT: 'Edit this form',
            EXPORT: 'Export/Download this form',
            IMPORT:'Import form (allows .json format only)',
        },
        PROCESS :  {
            DELETE: 'Delete this process',
            EDIT: 'Edit this process',
            EXPORT: 'Export/Download this process',
            IMPORT:'Import process (allows .json format only)',
        },
        FILED :  {
            DELETE: 'Delete this field',
            EDIT: 'Edit this field',
            SAVE: 'Save to library', 
            SELECT: 'Select this field ', 
        },
    },
    SECTION_NAME : [{ key: "section1", name: 'Section 1' },{ key: "section2", name: 'Section 2' },{ key: "section3", name: 'Section 3' }],
    CLIENT_REPOSITORY_LIST : [{id: 48,name: 'DELMONTE'},{id: 21,name: 'JSPL'},{id: 27,name: 'TECHPANION'}, {id: 32,name: 'SSC V2 DEMO'},{id: 17,name: 'ORL'},{id: 28,name: 'CLICKTECH'},{id: 30,name: 'ITC'}, {id: 49,name: 'EPSILON'}, {id: 50,name: 'COMPLIANCE CHECK'}, {id: 51,name: 'Epsilon QA'}],
    ADMIN_USER : [{id: 27,name: 'TECHPANION'}],
    DEFAULT_SELECTED_CLIENT_REPOSITORY_LIST : {id: 17,name: 'ORL'},
    TEMPLATE_TYPE: [{ key: "email", name: 'Email' }, { key: "pdf", name: 'PDF' }],
    MASKING_TYPES: [{ key: "first_four_characters", name: 'First four Characters' }, { key: "last_four_characters", name: 'Last four Characters' },{ key: "all_characters", name: 'All Characters' }],    
    DEFAULT_CONFIG_TO_FORMFIELDS:[{type:'all',fieldList:[{key:'dropdownDisplayConfig', defaulValue:''},{key:'enableDropdownDisplay', defaulValue:false},{key:'typeofMasking', defaulValue:''},{key:'enableMasking', defaulValue:false},{key:'makeThisPIField', defaulValue:false},{key:'hide', defaulValue:false},{key:'enableTooltip', defaulValue:false}] }, {type:'radio',fieldList:[{key:'enableSingleLineLabel', defaulValue:false}]}, {type:'invoiceUpload',fieldList:[{key:'required', defaulValue:false}]},{type:'fileUpload',fieldList:[{key:'fileFor', defaulValue:''},{key:'maxFileUploadCount', defaulValue:null}]}, {type:'fileUpload',fieldList:[{key:'required', defaulValue:false}]}, {type:'view',fieldList:[{key:'textTransform', defaulValue:'noTransform'}]}, {type:'document-view',fieldList:[{key:'textTransform', defaulValue:'noTransform'}]}, {type:'input',fieldList:[{key:'textTransform', defaulValue:'noTransform'},{key:'charLimit', defaulValue:''}]},
    {type:'table-default-edit',fieldList:[{key:'enableforFecthApi', defaulValue:true},{key:'variableforDataMapping', defaulValue:''}]},
    {type:'input-search',fieldList:[{key:'microflowDetails', defaulValue:MICROFLOWS_INPUT_FIELD_DATA},{key:'buttonLabel', defaulValue:''}]},
    {type:'checkbox',fieldList:[{key:'enableMultipleSelect', defaulValue:false},{key:'options', defaulValue:[]}]},
    {type:'invoiceUpload',fieldList:[ {key:'uploadAPIMethod', defaulValue:''}, {key:'uploadRequestBodyMap', defaulValue:[]}, {key:'uploadRequestBodyMapJson', defaulValue:[]}, {key:'uploadResponseMapping', defaulValue:[]}, {key:'uploadResponseMappingJson', defaulValue:[]}, ]},
    {type:'autocomplete-common',fieldList:[ {key:'enableMicroflow', defaulValue:false}, {key:'selectedMicroflow', defaulValue:''} ]},
    {type:'attachment-details',fieldList:[ {key:'uploadAPIMethod', defaulValue:''}, {key:'uploadRequestBodyMap', defaulValue:[]}, {key:'uploadRequestBodyMapJson', defaulValue:[]}, {key:'uploadResponseMapping', defaulValue:[]}, {key:'uploadResponseMappingJson', defaulValue:[]},  {key:'enableInvoiceSubmit', defaulValue:false}, ]},
    {type:'footerConfig',fieldList:[ {key:'enableValidation', parentArray:"actionButton", defaulValue:false}, {key:'enableinGroupView', parentArray:"actionButton", defaulValue:false} ]},
    {type:'action-history',fieldList:[{key:'uploadApiConfig', defaulValue:{ apiEndPoint: '', method: '', requestBodyMap: [], requestBodyMapJson: [], responseMapping: [], responseMappingJson: [] }}]}],

    PROCESS_PAGE : {
        PROCESS_CREATION_MANDATORY_VALUES :[
            { fieldtype : 'tab', requiredField:'key',requiredValue:'invoiceData'}, 
            { fieldtype : 'tab', requiredField:'updateDraftApiEndPoint',requiredValue:'invoice-header'}, 
            { fieldtype : 'header', requiredField:'getApiEndPoint',requiredValue:'invoice-header/taskId'}, 
        ],
        PROCESS_CREATION_MANDATORY_ERROR_MSG:'Following key and value are mandotry',
    },
    FORMS_PAGE : {
        FORMS_EXPORT_KEY : [{ columnHeading: 'Name', apiRef: 'name' }, { columnHeading: 'Label', apiRef: 'label' }, { columnHeading: 'Key', apiRef: 'key' }],
    },
    TEMPLATE_PAGE :{
        CKEDITOR_4_PATH:'/assets/js/ckeditor4-editor/ckeditor.js',
        FORMAT_OPTIONS:[{ key: "A0", name: 'A0' },{ key: "A1", name: 'A1' },{ key: "A2", name: 'A2' },{ key: "A3", name: 'A3' },{ key: "A4", name: 'A4' }],
        ORIENTATION_OPTIONS:[{ key: "portrait", name: 'Portrait' },{ key: "landscape", name: 'Landscape' }]
    },
    CLIENTS_PAGE : {
        UPDATE_BUTTON_TEXT: 'Update Client Detials',
    },
    MICROFLOW_PAGE : {
        ACTION_TYPE_LIST: [{ key: "db-call", name: 'DB Call' }, { key: "api-call", name: 'API Call' }, { key: "call-procedure", name: 'Procedure Call' }, { key: "rule", name: 'Rule' }],
        STEP_TYPE_LIST: [{ key: "RULE", name: 'Rule' }, { key: "ACTION", name: 'Action' }],
    }

}

