import { Component, EventEmitter, Input,  Output } from '@angular/core'; 


@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html', 
})
export class PaginationComponent {

    // Pagigantion
    @Input() curentPage: number;
    @Input() pageSize: number = 1;
    @Input() pageElementCount: number; 
    @Output("paginationLoad") paginationLoad: EventEmitter<any> = new EventEmitter(); 

 

  loadPage(curentPage: number,pageSize:number) {
    let _d ={
      curentPage : curentPage,
      pageSize : pageSize
    } 
    this.paginationLoad.emit(_d);
  }

}
