import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs'; 

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationAPIRspInterface } from 'app/main/authentication/interface/authentication-interface';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;


  public loggedInUserSource = new BehaviorSubject(null);
  public currentUserObserv = this.loggedInUserSource.asObservable();


  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private cookieService: CookieService, private _http: HttpClient, private _toastrService: ToastrService, private _router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
 
    this.loggedInUserSource.next(JSON.parse(localStorage.getItem('currentUser'))); 
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

    // getter: currentUserValue
    public get currentActiveuserValue(): User {
      return this.loggedInUserSource.value;
    }

    
  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }
 
  
  login(parmData){    
    const httpOptions = {
      headers: new HttpHeaders({
        'clientid':  `${parmData?.repository?.id}`
      })
    };   
    return this._http.post<AuthenticationAPIRspInterface>(`${environment.apiUrl}/auth/login`, parmData, httpOptions);  
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token'); 
    localStorage.removeItem('clientid'); 
    this._router.navigate(['/auth/login']);
    // notify
    this.cookieService.deleteAll();
    this.currentUserSubject.next(null);
  } 

setLocalStorage(data : object, accessToken : string, clientid?:any){ 
  localStorage.setItem('currentUser', JSON.stringify(data)); 
  localStorage.setItem('token', accessToken); 
  localStorage.setItem('clientid', JSON.stringify(clientid.id)); 
  this.loggedInUserSource.next(data); 
  return true;
}

  getData(){
    return this._http.get<any>('https://jsonplaceholder.typicode.com/todos/1');
  }

  sendResetPwdLink(parmData){
    return this._http.post(`${environment.apiUrl}/auth/forgot-password/send-reset-link`, parmData); 
  }

  resetPWD(parmData){
    return this._http.post(`${environment.apiUrl}/auth/update-password`, parmData); 
  }
}
