import { CoreMenu } from '@core/types'

export const adminMenu: CoreMenu[] = [  
  {
    id: 'clients',
    title: 'Clients',
    translate: 'Clients',
    type: 'item',
    icon: 'users',
    url: '/clients/clients-lp'
  }, 
]
