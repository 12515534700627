import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module'; 

import { JwtInterceptor, ErrorInterceptor, AuthGuard } from 'app/auth/helpers';
import { CookieService } from 'ngx-cookie-service'; 

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { OrderByPipe } from './pipes/order-by.pipe';
const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    //canActivate: [AuthGuard]
  }, 
  {
    path: 'input-config-pages',
    loadChildren: () => import('./main/pages/input-field-config/input-field-config.module').then(m => m.InputFieldConfigModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'process-pages',
    loadChildren: () => import('./main/pages/process/process.module').then(m => m.ProcessModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'publish-process',
    loadChildren: () => import('./main/pages/publish-process/publish-process.module').then(m => m.PublishProcessModule),
    canActivate: [AuthGuard]
  }, 
  {
    path: 'template',
    loadChildren: () => import('./main/pages/templates/templates.module').then(m => m.TemplatesModule),
    canActivate: [AuthGuard]
  }, 
  {
    path: 'clients',
    loadChildren: () => import('./main/pages/clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'microflows',
    loadChildren: () => import('./main/pages/microflows/microflows.module').then(m => m.MicroflowsModule),
  }, 
  {
    path: 'auth',
    loadChildren: () => import('./main/authentication/authentication-routing.module').then(m => m.AuthRoutingModule)
  },
  {
    path: '',
    redirectTo:  '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'temp',
    redirectTo:  '/home',
    pathMatch: 'full'
  
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent, OrderByPipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    NgxDatatableModule,
    SweetAlert2Module.forRoot(),

    // App modules
    LayoutModule 
  ],
  providers: [CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, 
],

  bootstrap: [AppComponent]
})
export class AppModule {}
